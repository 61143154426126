import { TextField } from "@material-ui/core";

const Input = ({ value, setValue, label, ...props }) => {
  const changeValue = ({ target: { value } }) => {
    setValue(value);
  };

  return (
    <TextField
      label={label}
      {...props}
      onChange={changeValue}
      value={value}
      fullWidth
    />
  );
};

export default Input;
