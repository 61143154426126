import { Grid } from "@material-ui/core";
import styled, { createGlobalStyle } from "styled-components";
import Logo from "./Logo";

export const Title = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledLogo = styled(Logo)`
  margin: 0 2rem 0 0;
`;

export const H1 = styled.h1`
  font-size: 1.8rem;
  white-space: nowrap;
  margin: 1rem 0;
`;

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 1rem;
    padding: 0;
  }
`;

export const GridItem = ({ children }) => (
  <Grid item xs={12} sm={6} md={4}>
    {children}
  </Grid>
);

export const Code = styled.code`
  display: block;
  padding: 1rem;
  max-height: 20rem;
  overflow-y: auto;
  border: 1px solid lightgrey;
`;
