import { useState } from "react";
import Input from "./components/Input";
import { GridItem, H1, StyledLogo, Title } from "./components/Layout";
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";
import Signature from "./components/Signature";
import SignatureCode from "./components/SignatureCode";

const SIGNATURE_TYPES = ["Access All Areas", "Silberdraht"];

const COMPANY_NAME = {
  "Access All Areas": "Access All Areas STND UG (haftungsbeschränkt)",
  Silberdraht: "Silberdraht",
};

const URL = {
  "Access All Areas": "access-all-areas.io",
  Silberdraht: "silberdraht.tel",
};

const LOGO = {
  "Access All Areas": "aaa_logo.png",
  Silberdraht: "silberdraht_logo.png",
};

const LOGO_SIZE = {
  "Access All Areas": [190, 50],
  Silberdraht: [148, 50],
};

const generateUrl = (prefix, name) => {
  if (!name || name.length === 0) return;
  return `${prefix}/${name}`;
};

const getFacebook = (name) => generateUrl("https://www.facebook.com", name);
const getTwitter = (name) => generateUrl("https://twitter.com", name);
const getLinkedin = (name) => generateUrl("https://www.linkedin.com/in", name);
const getInstagram = (name) => generateUrl("https://www.instagram.com", name);
const getEMail = (name, url) => {
  if (!name || name.length === 0) return;
  return `${name}@${url}`;
};

function App() {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("Neue Bahnhofstr. 33, 10245 Berlin");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [type, setType] = useState(SIGNATURE_TYPES[0]);

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    setType(newType);
  };

  const signatureProps = {
    name,
    role,
    company: COMPANY_NAME[type],
    phone,
    email: getEMail(email, URL[type]),
    url: URL[type],
    address,
    logoName: LOGO[type],
    facebook: getFacebook(facebook),
    twitter: getTwitter(twitter),
    linkedin: getLinkedin(linkedin),
    instagram: getInstagram(instagram),
    logoSize: LOGO_SIZE[type],
  };

  return (
    <Container maxWidth="md">
      <Box mb={3}>
        <Title>
          <StyledLogo width={300} color="#19202a" />
          <H1>Signatur Generator</H1>
        </Title>
      </Box>

      <Grid container spacing={3}>
        <GridItem>
          <FormControl fullWidth>
            <InputLabel id="signature-type-label">Signatur-Typ</InputLabel>
            <Select
              labelId="signature-type-label"
              id="signature-type"
              value={type}
              onChange={handleTypeChange}
            >
              {SIGNATURE_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem>
          <Input value={name} setValue={setName} label="Name" />
        </GridItem>
        <GridItem>
          <Input value={role} setValue={setRole} label="Rollenbezeichnung" />
        </GridItem>
        <GridItem>
          <Input value={phone} setValue={setPhone} label="Telefon" />
        </GridItem>
        <GridItem>
          <Input
            value={email}
            setValue={setEmail}
            label="E-Mail"
            helperText="Nur der Teil vor dem @-Zeichen"
          />
        </GridItem>
        <GridItem>
          <Input value={address} setValue={setAddress} label="Adresse" />
        </GridItem>
        <GridItem>
          <Input
            value={facebook}
            setValue={setFacebook}
            label="Facebook"
            helperText="Nur Benutzername, keine URL"
          />
        </GridItem>
        <GridItem>
          <Input
            value={twitter}
            setValue={setTwitter}
            label="Twitter"
            helperText="Nur Benutzername, keine URL"
          />
        </GridItem>
        <GridItem>
          <Input
            value={linkedin}
            setValue={setLinkedin}
            label="LinkedIn"
            helperText="Nur Benutzername, keine URL"
          />
        </GridItem>
        <GridItem>
          <Input
            value={instagram}
            setValue={setInstagram}
            label="Instagram"
            helperText="Nur Benutzername, keine URL"
          />
        </GridItem>
      </Grid>

      <Box my={8}>
        <Paper elevation={3}>
          <Box p={3}>
            <Signature {...signatureProps} />
          </Box>
        </Paper>
      </Box>

      <SignatureCode {...signatureProps} />
    </Container>
  );
}

export default App;
