const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const imageUrl = (name) => [BASE_URL, "assets", name].join("/");

const Signature = ({
  name,
  role,
  company,
  phone,
  email,
  url,
  address,
  logoName,
  facebook,
  twitter,
  linkedin,
  instagram,
  logoSize,
}) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    style={{
      verticalAlign: "-webkit-baseline-middle",
      fontSize: "medium",
      fontFamily: "Arial",
    }}
  >
    <tbody>
      <tr>
        <td>
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              verticalAlign: "-webkit-baseline-middle",
              fontSize: "medium",
              fontFamily: "Arial",
            }}
          >
            <tbody>
              <tr>
                <td style={{ verticalAlign: "middle", minWidth: "200px" }}>
                  <h3
                    color="#000000"
                    style={{
                      margin: "0px",
                      fontSize: "18px",
                      color: "rgb(0, 0, 0)",
                    }}
                  >
                    {name
                      .trim()
                      .split(/\s+/)
                      .map((text) => <span>{text}</span>)
                      .reduce(
                        (acc, x) =>
                          acc === null ? [x] : [acc, <span>&nbsp;</span>, x],
                        null
                      )}
                  </h3>
                  <p
                    color="#000000"
                    fontSize="medium"
                    style={{
                      margin: "0px",
                      color: "inherit",
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    <span>{role}</span>
                  </p>
                  <p
                    color="#000000"
                    fontSize="medium"
                    style={{
                      margin: "0px",
                      fontWeight: 500,
                      color: "inherit",
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    {/* <span>Abteilung</span><span>&nbsp;|&nbsp;</span> */}
                    <span>{company}</span>
                  </p>
                </td>
                <td width={30}>
                  <div style={{ width: "30px" }} />
                </td>
                <td
                  color="#e5952e"
                  direction="vertical"
                  width={1}
                  style={{
                    width: "1px",
                    borderBottomStyle: "none",
                    borderLeftWidth: "1px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "rgb(229, 149, 46)",
                  }}
                />
                <td width={30}>
                  <div style={{ width: "30px" }} />
                </td>
                <td style={{ verticalAlign: "middle", minWidth: "200px" }}>
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                      fontSize: "medium",
                      fontFamily: "Arial",
                    }}
                  >
                    <tbody>
                      {phone && phone.length > 0 && (
                        <>
                          <tr style={{ verticalAlign: "middle" }} height={25}>
                            <td
                              width={30}
                              style={{
                                verticalAlign: "middle",
                                paddingRight: "10px",
                              }}
                            >
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                style={{
                                  verticalAlign: "-webkit-baseline-middle",
                                  fontSize: "medium",
                                  fontFamily: "Arial",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                      <span style={{ fontSize: "12px" }}>
                                        <img
                                          src={imageUrl("phone.png")}
                                          alt="Telefon:"
                                          width={20}
                                          height={20}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{ padding: "0px", color: "rgb(0, 0, 0)" }}
                            >
                              <a
                                href={`tel:${phone.replace(/\s/g, "")}`}
                                color="#000000"
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                  fontSize: "12px",
                                }}
                              >
                                <span>{phone}</span>
                              </a>
                            </td>
                          </tr>
                        </>
                      )}

                      {email && email.length > 0 && (
                        <>
                          <tr style={{ verticalAlign: "middle" }} height={25}>
                            <td
                              width={30}
                              style={{
                                verticalAlign: "middle",
                                paddingRight: "10px",
                              }}
                            >
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                style={{
                                  verticalAlign: "-webkit-baseline-middle",
                                  fontSize: "medium",
                                  fontFamily: "Arial",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                      <span style={{ fontSize: "12px" }}>
                                        <img
                                          src={imageUrl("mail.png")}
                                          alt="E-Mail:"
                                          width={20}
                                          height={20}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ padding: "0px" }}>
                              <a
                                href={`mailto:${email}`}
                                color="#000000"
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                  fontSize: "12px",
                                }}
                              >
                                <span>{email}</span>
                              </a>
                            </td>
                          </tr>
                        </>
                      )}

                      {url && url.length > 0 && (
                        <>
                          <tr style={{ verticalAlign: "middle" }} height={25}>
                            <td
                              style={{
                                verticalAlign: "middle",
                                paddingRight: "10px",
                              }}
                            >
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                style={{
                                  verticalAlign: "-webkit-baseline-middle",
                                  fontFamily: "Arial",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                      <span style={{ fontSize: "12px" }}>
                                        <img
                                          src={imageUrl("website.png")}
                                          alt="Internet:"
                                          width={20}
                                          height={20}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ padding: "0px" }}>
                              <a
                                href={`https://www.${url}`}
                                color="#000000"
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                  fontSize: "12px",
                                }}
                              >
                                <span>{url}</span>
                              </a>
                            </td>
                          </tr>
                        </>
                      )}

                      {address && address.length > 0 && (
                        <>
                          <tr style={{ verticalAlign: "middle" }} height={25}>
                            <td
                              width={30}
                              style={{
                                verticalAlign: "middle",
                                paddingRight: "10px",
                              }}
                            >
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                style={{
                                  verticalAlign: "-webkit-baseline-middle",
                                  fontSize: "medium",
                                  fontFamily: "Arial",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                      <span style={{ fontSize: "12px" }}>
                                        <img
                                          src={imageUrl("address.png")}
                                          alt="Anschrift:"
                                          width={20}
                                          height={20}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ padding: "0px" }}>
                              <span
                                color="#000000"
                                style={{
                                  fontSize: "12px",
                                  color: "rgb(0, 0, 0)",
                                }}
                              >
                                <span>{address}</span>
                              </span>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              verticalAlign: "-webkit-baseline-middle",
              fontSize: "medium",
              fontFamily: "Arial",
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td height={30} />
              </tr>
              <tr>
                <td
                  color="#e5952e"
                  direction="horizontal"
                  height={1}
                  style={{
                    width: "100%",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "solid",
                    borderBottomColor: "rgb(229, 149, 46)",
                    borderLeftStyle: "none",
                    display: "block",
                  }}
                />
              </tr>
              <tr>
                <td height={20} />
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              verticalAlign: "-webkit-baseline-middle",
              fontSize: "medium",
              fontFamily: "Arial",
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td style={{ verticalAlign: "middle" }}>
                  <img
                    src={imageUrl(logoName)}
                    role="presentation"
                    style={{
                      width: `${logoSize[0]}px`,
                      height: `${logoSize[1]}px`,
                      display: "inline-block",
                    }}
                    width={logoSize[0]}
                    height={logoSize[1]}
                    alt=""
                  />
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                      fontSize: "medium",
                      fontFamily: "Arial",
                      display: "inline-block",
                    }}
                  >
                    <tbody>
                      <tr style={{ textAlign: "right" }}>
                        {facebook && (
                          <>
                            <td>
                              <a
                                href={facebook}
                                style={{
                                  display: "inline-block",
                                  padding: "0px",
                                }}
                              >
                                <img
                                  src={imageUrl("facebook.png")}
                                  alt="Facebook"
                                  width={25}
                                  height={25}
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    display: "block",
                                  }}
                                />
                              </a>
                            </td>
                            <td width={5}>
                              <div />
                            </td>
                          </>
                        )}

                        {twitter && (
                          <>
                            <td>
                              <a
                                href={twitter}
                                style={{
                                  display: "inline-block",
                                  padding: "0px",
                                }}
                              >
                                <img
                                  src={imageUrl("twitter.png")}
                                  alt="Twitter"
                                  width={25}
                                  height={25}
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    display: "block",
                                  }}
                                />
                              </a>
                            </td>
                            <td width={5}>
                              <div />
                            </td>
                          </>
                        )}

                        {linkedin && (
                          <>
                            <td>
                              <a
                                href={linkedin}
                                style={{
                                  display: "inline-block",
                                  padding: "0px",
                                }}
                              >
                                <img
                                  src={imageUrl("linkedin.png")}
                                  alt="LinkedIn"
                                  width={25}
                                  height={25}
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    display: "block",
                                  }}
                                />
                              </a>
                            </td>
                            <td width={5}>
                              <div />
                            </td>
                          </>
                        )}

                        {instagram && (
                          <>
                            <td>
                              <a
                                href={instagram}
                                style={{
                                  display: "inline-block",
                                  padding: "0px",
                                }}
                              >
                                <img
                                  src={imageUrl("instagram.png")}
                                  alt="Instagram"
                                  width={25}
                                  height={25}
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    display: "block",
                                  }}
                                />
                              </a>
                            </td>
                            <td width={5}>
                              <div />
                            </td>
                          </>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

Signature.defaultProps = {
  name: "",
};

export default Signature;
