import { useState } from "react";
import { Box, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ReactDOMServer from "react-dom/server";
import { Code } from "./Layout";
import Signature from "./Signature";
import setClipboard from "copy-text-to-clipboard";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const SignatureCode = (props) => {
  const [notification, setNotification] = useState(false);
  const code = ReactDOMServer.renderToStaticMarkup(<Signature {...props} />);

  const copyCode = () => {
    const success = setClipboard(code);
    if (success) setNotification(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification(false);
  };

  return (
    <>
      <Snackbar
        open={notification}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Die Signatur wurde erfolgreich in die Zwischenablage kopiert.
        </Alert>
      </Snackbar>

      <Box mb={2}>
        <Button variant="contained" onClick={copyCode} color="primary">
          In die Zwischenablage kopieren
        </Button>
      </Box>

      <Code>{code}</Code>
    </>
  );
};

export default SignatureCode;
